<template lang="pug">
	div
		project-alerts
		.tariffs.d-flex.flex-wrap.justify-content-between.align-items-start
			.tariffs__content
				h2.tariffs__title {{ $t('promotions.promotionsInfo.tariffs') }}

				.foreground-color.basic-box-shadow.border-rounded.tariffs__payment(v-if="item && item.isNeuro() && item.isActive()")
					h2.b1.tariffs__payment-title {{ $t('project.link') }}
					span.b1.text-grey {{ $t('tariffs.viewTariffs') }}
					.d-flex.justify-content-between.align-items-center.tariffs__payment-link
						a.b1.text-break.text-primary(:href='paymentLinkDefault' target='_blank') {{ paymentLinkDefault }}
						.d-flex(v-if="!isMobile")
							i.text-primary.fontello.fontello-icon-edit.cursor-pointer.btn-icon.mr-10(@click="$router.push({name: 'project_settings', params: {id: item.id}})")
							i.text-primary.fontello.fontello-icon-copy.cursor-pointer.btn-icon(@click="copy(paymentLinkDefault)")
						i.text-primary.fontello.fontello-icon-dots.mb-auto(v-if="isMobile" @click="onOpenPopover('popoverConstant')" id="link-constant")
						b-popover(v-if="isMobile" ref="popoverConstant" target="link-constant")
							i.fontello-btn.fontello-btn-primary.fontello-icon-edit.tariffs__btn-popover(@click="$router.push({name: 'project_settings', params: {id: item.id}}), onClosePopover('popoverConstant')")
							i.fontello-btn.fontello-btn-primary.fontello-icon-copy.tariffs__btn-popover(@click="copy(paymentLinkDefault), onClosePopover")

				.foreground-color.basic-box-shadow.border-rounded.tariffs__wrap
					h3.h3.text-bold.tariffs__subtitle {{ $t('tariffs.fixedCost') }}
					.tariffs__inner
						.tariffs__cards
							.border-rounded.tariffs__card-info(v-for='item in tariffInfo' :key='item')
								span.b3 {{ item }}
</template>

<script>
import { mapGetters } from 'vuex';
import ProjectAlerts from '@/components/Cards/ProjectAlerts';

export default {
    name: 'Tariffs',
    components: {
        ProjectAlerts,
    },
    computed: {
        ...mapGetters({
            item: 'project/opened',
        }),
        paymentLink() {
            return this.item ? `${this.paymentLinkDomain}${this.item.hashIdCustom}` : '';
        },
        paymentLinkDefault() {
            if (!this.item) return '';

            if (this.item.hashIdCustom != this.item.hashId) return this.paymentLink;

            return this.item ? `${this.paymentLinkDomain}${this.item.hashId}` : '';
        },
        paymentLinkDomain() {
            return `${window.location.protocol}//${window.location.host.replace('merchant.', '')}/`;
        },
        tariffInfo() {
            return this.$t('tariffs.info');
        },
    },
    mounted() {
        window.addEventListener('click', e => {
            if (this.overlay === true) {
                const target = e.target;

                if (!target.closest('.b-popover') && !target.closest('.fontello-icon-dots')) {
                    this.onClosePopover();
                }
            }
        });
    },
    methods: {
        async copy(text) {
            try {
                await this.$copyText(text);
                this.$notify('success', `😊 &nbsp;&nbsp;&nbsp;${this.$t('success.copyLink')}`);
            } catch (e) {
                console.log(e);
            }
        },
        onOpenPopover(refs) {
            if (refs == 'popoverConstant') {
                this.$refs.popoverConstant.$emit('open');
            } else if (refs == 'popoverAdditional') {
                this.$refs.popoverAdditional.$emit('open');
            }
            document.body.classList.add('overlay');
            this.overlay = true;
        },
        onClosePopover() {
            if (this.$refs.popoverConstant) {
                this.$refs.popoverConstant.$emit('close');
            }

            if (this.$refs.popoverAdditional) {
                this.$refs.popoverAdditional.$emit('close');
            }
            document.body.classList.remove('overlay');
            this.overlay = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.tariffs {
    &__content {
        max-width: 935.5px;
    }

    &__title {
        margin-bottom: 22px !important;

        @include respond-below(xs) {
            margin-bottom: 20px !important;
        }
    }

    &__payment {
        padding: 20.5px 21px;
        margin-bottom: 20px;

        @include respond-below(xs) {
            padding: 14px 17px 15px 17px;
        }

        &-title {
            margin-bottom: 10px !important;

            @include respond-below(xs) {
                margin-bottom: 20px !important;
            }
        }

        &-link {
            margin-top: 10px;
            padding: 9px 20px;
            border-radius: 15px;
            background-color: var(--background-color);

            @include respond-below(xs) {
                margin-top: 11px;
                padding: 10px 15px;

                .b1 {
                    font-size: 14px !important;
                    line-height: 22px !important;
                }
            }

            .fontello-icon-dots {
                margin-left: 10px;
            }
        }
    }

    &__wrap {
        width: 100%;
        padding: 21px 20px 19px 20px;

        @include respond-below(xs) {
            padding: 20px;
        }
    }

    &__card-info {
        padding: 20px 19px;
        border: 1px solid var(--grayscale-dark-line);
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__cards {
        margin-top: 20px;
    }

    &__btn-popover {
        @include respond-below(sm) {
            margin: 5px 0;
        }
    }
}
</style>
